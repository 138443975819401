body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100% !important;
  overflow: hidden;
}   

.eanscannermodal {
  margin-top: 1rem !important;
}

#eanscanner video {
  width: 100%;
  height: auto;
}
#eanscanner canvas {
  position: absolute;
}

.unsetBold {
  font-weight: 100 !important;
}

.bottomSpace {
  margin-bottom: 0.5rem !important;
}

.removeDimmerBorders {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  width: 100%;
  box-shadow: none !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}
